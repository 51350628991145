<template>
  <v-container fluid>
    <v-form ref="formulario">
      <div class="d-flex flex-wrap mb-4" v-if="!noEdit">
        <v-tooltip v-if="!estudio.estado || estudio.estado == 'Borrador'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="mx-1" color="primary" large :loading="subiendoEstudio"
              @click="enviarEstudio(true)">
              <v-icon color="white" left>mdi-content-save</v-icon>GUARDAR
              BORRADOR
            </v-btn>
          </template>
          <span>Guardar el estudio temporalmente como borrador</span>
        </v-tooltip>
        <v-tooltip v-if="idEstudio" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="mx-1" color="primary" large :loading="subiendoEstudio"
              @click="enviarEstudio(false)" :disabled="documentos.length == 0 && !ignorarDoc">
              <v-icon color="white" left>mdi-upload</v-icon>
              {{
                estudio.estado_actual?.estado == "Borrador"
                  ? "ENVIAR A REVISIÓN"
                  : "GUARDAR CAMBIOS"
              }}
            </v-btn>
          </template>
          <span>Validar los datos y enviar a revisión</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" large text class="mx-1" :loading="subiendoEstudio"
              @click="erroresCampos = !$refs.formulario.validate()" :color="erroresCampos == null ? '' : erroresCampos ? 'error' : 'success'
                ">
              <v-icon left v-if="erroresCampos === true">mdi-alert-circle-outline</v-icon>
              <v-icon left v-else-if="erroresCampos === false">mdi-check</v-icon>Consultar campos
            </v-btn>
          </template>
          <span v-if="!erroresCampos">Consultar campos faltantes o erroneos</span>
          <span v-else>Hay campos erróneos, revisa todos los campos</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip v-if="idEstudio && estudio.estado_actual?.estado == 'Borrador'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="mx-1" color="error" large :loading="subiendoEstudio"
              @click="eliminarEstudio(false)">
              <v-icon color="white" left>mdi-delete</v-icon>ELIMINAR BORRADOR
            </v-btn>
          </template>
          <span>Eliminar borrador</span>
        </v-tooltip>
      </div>
      <!-- LOG -->
      <div style="gap: 10px" :class="[noEdit || 'my-4', 'd-flex flex-wrap', 'align-center']">
        <v-alert class="mb-0" dense :icon="getSituacionIcon(estudio.estado).icon"
          :color="getSituacionIcon(estudio.estado).color" text v-if="estudio.estado && estudio.estado != 'Borrador'">{{
            estudio.estado }}</v-alert>
        <v-alert class="mb-0" dense type="info" text v-if="tipoSeleccionado">{{
          tipoSeleccionado.nombreTipo
          }}</v-alert>
        <v-alert class="mb-0" dense type="info" text v-if="!idEstudio">
          La documentación no está disponible hasta que el borrador esté creado
        </v-alert>

        <v-alert class="mb-0" dense type="warning" text v-else-if="documentos.length == 0 && !ignorarDoc">Es obligatorio
          adjuntar la documentación</v-alert>

        <v-checkbox label="Ignorar documentación" dense class="ma-0" hide-details v-model="ignorarDoc"
          v-if="$root.acceso('EDITAR_ESTUDIOS')"></v-checkbox>

        <v-alert v-for="(error, index) in errors" :key="index" type="error" text class="ma-0">{{ error }}</v-alert>
      </div>

      <v-tabs v-model="tab">
        <v-tab>Proyecto</v-tab>
        <v-tab>Factura Datos</v-tab>
        <v-tab>Geolocalización</v-tab>
        <v-tab v-if="estudio.idTipo == 7">Cargador VE</v-tab>
        <v-tab v-if="estudio.idTipo == 1008">Ventanas</v-tab>
      </v-tabs>

      <v-tabs-items class="pa-2" touchless v-model="tab">
        <v-tab-item eager>
          <v-row>
            <v-col cols="12" md="6">
              <tarjeta-datos header titulo="Descripción del proyecto" icon="format-list-bulleted">
                <aux-input title="Nombre proyecto (Cliente)">
                  <v-text-field filled dense hide-details="auto" :rules="[rules.req]"
                    v-model="estudio.nombreProyecto"></v-text-field>
                </aux-input>
                <v-divider />
                <aux-input title="Descripción proyecto & observaciones">
                  <v-textarea filled dense hide-details="auto" no-resize :rules="[rules.req]"
                    v-model="estudio.descripcion"></v-textarea>
                </aux-input>
                <v-divider />
                <aux-input title="Interés del cliente">
                  <v-select filled dense hide-details="auto" v-model="estudio.idNivelInteres"
                    :items="selects.nivelesInteres" item-value="idNivelInteres" clearable>
                  </v-select>
                </aux-input>
                <!-- <v-divider />
								<aux-input title="Formato estudio (opcional)">
									<v-text-field filled dense hide-details="auto" clearable v-model="estudio.formatoEstudio"></v-text-field>
								</aux-input> -->
              </tarjeta-datos>
            </v-col>
            <v-col v-if="idEstudio" cols="12" md="6">
              <!-- <tarjeta-datos header titulo="Documentación" icon="file-document"> -->
              <DocumentacionEstudio class="pa-0" :idEstudio="Number(idEstudio)" v-model="documentos" />
              <!-- </tarjeta-datos> -->
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item eager>
          <v-row>
            <v-col cols="12" md="6">
              <tarjeta-datos header icon="domain" titulo="Datos suministro">
                <aux-input title="CUPS *">
                  <v-text-field dense hide-details="auto" filled placeholder="CUPS"
                    v-model="estudio.cups_principal.cups" :rules="[rules.cups, rules.req]" class="mb-1">
                    <template v-slot:append>
                      <div class="mt-n2">
                        <CountryFlag :country="countryFlag" />
                      </div>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn class="mt-n2" color="primary" @click="buscarCups" :loading="isLoading">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>

                  <div class="d-flex align-center" v-for="(c, i) in estudio.cups" :key="i">
                    <v-text-field filled dense v-model="estudio.cups[i].cups" hide-details="auto" class="my-1"
                      :rules="[rules.cups]" />
                    <v-btn @click.stop="estudio.cups.splice(i, 1)" color="error" icon small>
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <v-btn class="mt-2" @click.stop="estudio.cups.push({ cups: '', direccion: '' })" fab small
                    color="primary">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </aux-input>
                <v-divider />
                <aux-input title="Dirección suministro">
                  <v-text-field filled dense v-model="estudio.cups_principal.direccion" hide-details="auto"
                    :rules="[rules.req]" placeholder="Dirección suministro">
                    <template v-slot:append-outer>
                      <v-btn class="mt-n2" color="primary" @click="buscarDireccion" :loading="isLoading">
                        <v-icon>mdi-google-maps</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </aux-input>
              </tarjeta-datos>

              <tarjeta-datos header icon="account" class="mt-6" titulo="Cliente">
                <aux-input title="DNI/CIF">
                  <v-text-field filled dense v-model="estudio.cliente.nif" hide-details="auto" :rules="[rules.dni]"
                    placeholder="DNI/CIF">
                    <template v-slot:append>
                      <div class="mt-n2">
                        <CountryFlag :country="clientFlag" />
                      </div>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn class="mt-n2" color="primary" @click="buscarCliente" :loading="isLoading">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </aux-input>
                <v-divider />
                <aux-input title="Nombre cliente / Razon Social">
                  <v-text-field filled dense v-model="estudio.cliente.nombre" hide-details="auto"
                    placeholder="Nombre completo / Razon Social" />
                </aux-input>
                <v-divider />
                <aux-input title="Nombre Representante">
                  <v-text-field filled dense v-model="estudio.cliente.nombreRepresentante" hide-details="auto"
                    placeholder="Nombre Completo" />
                </aux-input>
                <v-divider />
                <aux-input title="DNI Representante">
                  <v-text-field filled dense v-model="estudio.cliente.nifRepresentante" hide-details="auto"
                    :rules="[rules.dni]" placeholder="DNI representante" />
                </aux-input>
                <v-divider />
                <aux-input title="Contactos">
                  <div class="d-flex pa-1 flex-column">
                    <div class="d-flex align-center" v-for="(c, i) in estudio.cliente.contactos" :key="i">
                      <!-- <v-select
                        filled
                        dense
                        hide-details="auto"
                        :items="selects.tipoContactos"
                        v-model="estudio.cliente.contactos[i].tipo"
                        item-key="tipo"
                        text-key="text"
                        class="flex-grow-0"
                      >
                        <template v-slot:selection="{ item }" >
                          <v-icon small left>{{ item.icon }}</v-icon>
                          {{item.text}}
                        </template>
                        <template v-slot:item="{ item }" >
                          <v-icon small left>{{ item.icon }}</v-icon>
                          {{item.text}}
                        </template>
                      </v-select> -->
                      <div class="d-flex flex-column mr-2">
                        <v-radio-group dense v-model="estudio.cliente.contactos[i].tipo">
                          <v-radio v-for="(tipo, i) in selects.tipoContactos" :key="i" :label="tipo.text"
                            :value="tipo.tipo"></v-radio>
                        </v-radio-group>
                      </div>
                      <v-text-field filled dense v-model="estudio.cliente.contactos[i].valor" hide-details="auto"
                        class="my-1 flex-grow-1" />
                      <v-btn @click.stop="estudio.cliente.contactos.splice(i, 1)" color="error" icon small>
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-btn @click.stop="estudio.cliente.contactos.push({})" fab small color="primary">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </aux-input>
                <v-divider />
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex flex-column" style="gap: 24px">
                <tarjeta-datos header icon="file-document-outline" titulo="Datos contrato">
                  <aux-input title="Tarifa *">
                    <v-select filled placeholder="Seleccione una tarifa" dense hide-details="auto"
                      v-model="estudio.idTarifa" clearable :items="selects.tarifas" @change="changeTarifa"
                      item-text="text" item-value="value" :rules="[rules.req]" />
                  </aux-input>

                  <v-divider />
                  <v-row class="ma-1" v-if="estudio.potencias">
                    <v-col cols="12" class="col-md-6" v-for="([key, val], i) in Object.entries(
                      estudio.potencias
                    )" :key="i">
                      <aux-input>
                        <template v-slot:title>
                          <h5>{{ key }}</h5>
                          <small>
                            {{ val.potencia }}
                          </small>
                        </template>
                        <v-text-field filled dense hide-details="auto" :placeholder="`Precio ${key}`"
                          v-model="val.precio" label="Precio energía" :rules="[rules.isNumber]" />
                      </aux-input>
                    </v-col>
                  </v-row>
                  <div class="d-flex ma-4 mt-0">
                    <v-alert class="ma-0" type="info" dense text>ATR + Coste</v-alert>
                  </div>
                  <v-divider />
                  <aux-input title="Consumo Anual SIPS KWh">
                    <v-text-field dense hide-details="auto" filled disabled placeholder="Introduzca consumo del SIPS"
                      v-model="estudio.consumoAnualSips" />
                  </aux-input>
                </tarjeta-datos>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item eager>
          <v-row v-if="!cargandoDatos">
            <v-col cols="12" md="8">
              <tarjeta-datos header titulo="Mapa" icon="map">
                <div style="min-height: 500px; display: grid">
                  <Map ref="map" :center="{
                    lat: Number(estudio.latitud || 0),
                    lng: Number(estudio.longitud || 0),
                  }" :zoom="estudio.freezePosition ? 16 : 6" v-model="estudio.cubiertas"
                    :marker="estudio.freezePosition" @changePosition="({ latitud, longitud }) => {
                      estudio.latitud = latitud;
                      estudio.longitud = longitud;
                      estudio.freezePosition = true;
                    }
                      " />
                </div>
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos header titulo="Coordenadas" icon="google-maps">
                <aux-input title="Latitud">
                  <v-text-field filled dense hide-details="auto" :rules="[rules.req]" v-model="estudio.latitud"
                    label="Latitud"></v-text-field>
                </aux-input>
                <v-divider />
                <aux-input title="Longitud">
                  <v-text-field filled dense hide-details="auto" :rules="[rules.req]" v-model="estudio.longitud"
                    label="Longitud"></v-text-field>
                </aux-input>
                <v-divider />
                <v-subheader>Cubiertas seleccionadas</v-subheader>
                <div class="d-flex flex-column ma-4" style="gap: 16px">
                  <v-card width="100%" v-for="c in cubiertasMarcadas" :key="c.id" class="py-3">
                    <v-row no-gutters>
                      <v-col cols="auto" class="d-flex justify-center align-center">
                        <v-avatar @click.stop="
                          estudio.latitud = c.center.lat;
                        estudio.longitud = c.center.lng;
                        " :color="c.color" size="30" class="mx-2" />
                      </v-col>
                      <v-col class="d-flex flex-column mx-2">
                        <v-text-field v-model="c.nombre" single-line append-icon="mdi-feather" dense hide-details="auto"
                          :rules="[rules.req, rules.nombreCubierta]" :hint="String(c.area)" persistent-hint />
                      </v-col>
                      <v-col cols="auto" class="d-flex justify-center align-center mr-2">
                        <v-btn color="error" icon @click.stop="removePolygon(c)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </tarjeta-datos>
              <tarjeta-datos header titulo="Otros datos (OPCIONAL)" icon="cog" class="mt-4">
                <aux-input title="Uralita">
                  <v-checkbox dense hide-details="auto" class="mt-0" v-model="estudio.uralita"></v-checkbox>
                </aux-input>
                <v-divider />
                <aux-input title="Inclinación">
                  <v-text-field filled dense hide-details="auto" v-model="estudio.inclinacion" suffix="º" />
                </aux-input>
                <v-divider />
                <aux-input title="Azimut">
                  <v-text-field filled dense hide-details="auto" v-model="estudio.azimut" suffix="º" />
                </aux-input>
                <!--- <v-divider />
								<aux-input title="Material cubierta">
									<v-text-field filled dense hide-details="auto" v-model="estudio.material" />
								</aux-input>-->
              </tarjeta-datos>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item eager v-if="estudio.idTipo == 7">
          <tarjeta-datos titulo="Opciones cargador VE" icon="ev-station">
            <aux-input title="Número de unidades">
              <v-text-field filled clearable v-model="estudio.cargador.nTomas" dense hide-details="auto"
                type="number"></v-text-field>
            </aux-input>
            <v-divider />
            <aux-input title="Tipo de red">
              <v-select filled clearable v-model="estudio.cargador.tipo" :items="[
                { text: 'Monofásico', value: 'MONOFASICO' },
                { text: 'Trifásico', value: 'TRIFASICO' },
              ]" dense hide-details="auto"></v-select>
            </aux-input>
            <v-divider></v-divider>
            <aux-input>
              <template v-slot:title>
                <h5>Metros de cable</h5>
                <h6>(cuadro prot. a cargador)</h6>
              </template>
              <v-text-field filled clearable v-model="estudio.cargador.metrosCable" dense hide-details="auto"
                type="number"></v-text-field>
            </aux-input>
            <v-divider></v-divider>
            <aux-input title="Metros de manguera">
              <v-select filled clearable v-model="estudio.cargador.tipoConector" :items="selects.tiposManguera" dense
                hide-details="auto" type="number"></v-select>
            </aux-input>
            <v-divider></v-divider>
            <aux-input title="Soporte">
              <v-select filled clearable v-model="estudio.cargador.tipoSoporte" :items="selects.tiposSoporte" dense
                hide-details="auto" type="number"></v-select>
            </aux-input>
            <v-divider></v-divider>
            <aux-input title="Cable de repuesto">
              <v-text-field filled v-model="estudio.cargador.cableRepuesto" type="number" dense
                hide-details="auto"></v-text-field>
            </aux-input>
            <v-divider></v-divider>
            <aux-input>
              <template v-slot:title>
                <h5>Cargador portátil</h5>
                <h6>(Monofásico)</h6>
              </template>
              <v-text-field filled v-model="estudio.cargador.cargadorPortatil" type="number" dense
                hide-details="auto"></v-text-field>
            </aux-input>
          </tarjeta-datos>
        </v-tab-item>

        <!-- Ventanas -->
        <v-tab-item eager v-if="estudio.idTipo == 1008">
          <tarjeta-datos titulo="Ventanas" icon="window-closed-variant">
            <Ventanas v-model="estudio.ventanas"></Ventanas>
          </tarjeta-datos>
        </v-tab-item>
      </v-tabs-items>
    </v-form>

    <v-bottom-sheet :value="checksPanel" inset persistent>
      <v-card>
        <v-timeline dense>
          <v-slide-x-transition group>
            <v-timeline-item fill-dot color="transparent" class="align-center" v-for="(value, key) in checks"
              :key="key">
              <template v-slot:icon>
                <v-progress-circular v-if="
                  !isNaN(value.max) &&
                  (value.progress == null || value.progress == undefined)
                " :value="(value.actual * 100) / value.max" color="secondary"></v-progress-circular>
                <v-icon v-else-if="value.progress === false">mdi-minus</v-icon>
                <v-icon color="success" v-else-if="value.progress === true">mdi-check</v-icon>
                <v-icon color="error" v-else-if="value.progress === 'error'">mdi-close-octagon-outline</v-icon>
                <v-progress-circular v-else-if="value.progress === 'loading'" indeterminate
                  color="secondary"></v-progress-circular>
              </template>
              <h4 :class="[
                value.progress == 'error' ? 'error--text' : '',
                value.progress === true ? 'success--text' : '',
                value.progress === false ? 'grey--text' : '',
              ]">
                {{ value.texto }}
              </h4>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet :value="estudio == null || (estudio.idTipo == null && !checksPanel)" persistent>
      <v-card>
        <h3 class="h3 py-3 text-center">Tipo del estudio</h3>
        <div class="d-flex flex-wrap pa-4 justify-center">
          <TipoEstudio v-for="(tipo, i) in tiposEstudios" :key="i" :tipo="tipo"
            @click.stop="estudio.idTipo = tipo.idTipo" />
        </div>
      </v-card>
    </v-bottom-sheet>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import {
  getSituacionIcon,
} from "@/utils/index.js";
import {
  req,
  cups,
  isNumber,
  ValidateSpanishID,
  spainIdType,
} from "@/utils/validations.js";

export default {
  components: {
    CountryFlag: () => import("vue-country-flag"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    DocumentacionEstudio: () => import("../components/documentacion/index"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    Map: () => import("@/components/Map.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    TipoEstudio: () => import("../components/TipoEstudio.vue"),
    Ventanas: () => import("../components/Ventanas.vue"),
  },
  props: {
    noEdit: Boolean,
    _idEstudio: String | Number,
  },
  data() {
    return {
      idEstudio: this.$route.query.idEstudio || this._idEstudio,
      tab: 0,
      estudio: {
        idTipo: null,
        cliente: {
          contactos: [],
        },
        cups_principal: {},
        cups: [],
        cubiertas: [],
        potencias: {},
        cargador: {},
        ventanas: [],
      },
      debouncer: {
        direccion: null,
      },
      selects: {
        tarifas: [],
        tipoContactos: [
          { icon: "mdi-email", text: "Email", tipo: "E" },
          { icon: "mdi-phone", text: "Telf.", tipo: "T" },
        ],
        nivelesInteres: [],
        tiposManguera: [],
        tiposSoporte: [],
      },
      isLoading: false,
      isLoadingCalle: false,
      rules: {
        req,
        cups,
        isNumber,
        nombreCubierta: this.nombreCubierta,
        dni: ValidateSpanishID,
      },
      errors: [],
      erroresCampos: null,
      subiendoEstudio: null,
      cargandoDatos: false,
      checksPanel: false,
      checks: {},

      tiposEstudios: [],

      ignorarDoc: false,
      documentos: [],
    };
  },
  computed: {
    countryFlag() {
      return this.estudio?.cups_principal?.pais?.codigoISO || "";
    },
    clientFlag() {
      return this.estudio?.cliente?.pais?.codigoISO || "";
    },
    tipoSeleccionado() {
      return this.tiposEstudios.find((t) => t.idTipo == this.estudio.idTipo);
    },
    cubiertasMarcadas() {
      if (this.estudio.cubiertas == null || this.estudio.cubiertas.length == 0)
        return [];
      return this.estudio.cubiertas.filter((c) => c.polygon.visible);
    },
  },
  async mounted() {
    if (this.idEstudio) {
      await this.getDatosEstudio();
    }

    this.getTiposEstudio();
    this.getTarifas();
    this.getNivelesInteres();
    this.getTiposMangueras();
    this.getTiposSoporte();
  },
  methods: {
    getSituacionIcon,
    nombreCubierta(v) {
      const nombres = this.cubiertasMarcadas.map((c) => c.nombre);
      return (
        nombres.length == [...new Set(nombres)].length ||
        "El nombre no se puede repetir entre cubiertas"
      );
    },
    getDatosEstudio() {
      return new Promise((resolve, reject) => {
        this.$set(this.checks, "cargandoEstudio", {
          progress: "loading",
          texto: "Cargando datos del estudio",
        });
        this.cargandoDatos = true;
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
        })
          .then(async ({ data }) => {
            if (
              data.estado_actual.estado !== "Borrador" &&
              data.estado_actual.estado !== "Incidencia" &&
              data.estado_actual.estado !== "Prueba" &&
              data.estado_actual.estado !== "Pendiente Revisión"
            ) {
              this.$root.$emit(
                "snack",
                `Este estudio está como ${data.estado_actual.estado}, no puede ser editado`
              );
              this.$router.go(-1);
              return;
            }
            data.cliente ??= { contactos: [] };
            data.cups_principal ??= {};
            data.cargador ??= {};
            data.ventanas ??= [];

            this.estudio = data;
            this.checks.cargandoEstudio.progress = true;
            this.cargandoDatos = false;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
            this.checks.cargandoEstudio.progress = "error";
            this.cargandoDatos = false;
            // this.$root.$emit('snack', `Este estudio está como ${data.estado}, no puede ser editado`)
            this.$router.go(-1);
          });
      });
    },
    async buscarCups() {
      if (
        this.estudio.cups_principal.cups == "" ||
        this.estudio.cups_principal.cups == null ||
        this.rules.cups(this.estudio.cups_principal.cups) !== true
      )
        return;
      this.isLoading = true;

      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/cups/datos`,
        params: { cups: this.estudio.cups_principal?.cups },
      }).catch((err) => {
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al buscar los datos del suministro",
          "error"
        );
        this.isLoading = false;
        return;
      });

      this.estudio.cups_principal.direccion = `${data.Localidad_Suministro} ${data.Direccion_Suministro}`;

      this.estudio.idTarifa = this.selects.tarifas.find((tarifa) =>
        data.Tarifa.includes(tarifa.text)
      )?.idTarifa;

      const auxTarifa = this.selects.tarifas.find((tarifa) =>
        data.Tarifa.includes(tarifa.text)
      );

      Array.from(
        { length: auxTarifa.periodosEnergia },
        (_, i) => `p${i + 1}`
      ).forEach((periodo) => {
        this.$set(this.estudio.potencias, periodo, {
          potencia: data[`Pot_Cont_${periodo.toUpperCase()}`],
          estimado: data[`kWhAnual_${periodo}`],
          precio: this.estudio?.potencias?.[periodo]?.precio,
        });
      });

      this.estudio.consumoAnualSips = data.kWhAnual;
      this.isLoading = false;
      return;
    },
    async buscarDireccion() {
      this.isLoading = true;
      try {
        if (this.estudio.freezePosition) {
          this.isLoading = false;
          return;
        }
        const { lat, lng } = await this.$refs.map.buscarDireccion(
          this.estudio.cups_principal.direccion
        );
        this.estudio.latitud = lat;
        this.estudio.longitud = lng;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    async buscarCliente() {
      this.isLoading = true;
      try {
        const { data } = await axios({
          url: `${process.env.VUE_APP_API_URL}/clientes/busqueda`,
          params: { nif: this.estudio?.cliente?.nif },
        });

        if (data == null) {
          this.$root.$emit("snack", "No se ha encontrado el cliente", "error");
          this.isLoading = false;
          return;
        }

        this.estudio.cliente = data;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    changeTarifa(tarifa) {
      const aux = this.selects.tarifas.find((t) => t.idTarifa == tarifa);
      this.estudio.potencias = Object.create(null);
      for (let i = 0; i < aux.periodosEnergia; i++) {
        this.$set(this.estudio.potencias, `p${i + 1}`, {});
      }
    },
    estudioBuilder(vm) {
      return new Object({
        datos: {
          ...vm.estudio,
          cubiertas: vm.estudio.cubiertas
            .filter((c) => c.polygon.visible)
            .map((c) => ({ ...c, polygon: c.polygon.getPath().getArray() })),
          cronograma: null,
          mensajes: null,
        },

        guardarBorrador: function () {
          return new Promise((resolve, reject) => {
            axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_URL}/estudios`,
              data: {
                ...this.datos,
                idEstudio: vm.idEstudio || null,
                estado: "Borrador",
                idTipo: vm.estudio.idTipo,
              },
            })
              .then(({ data }) => resolve(data))
              .catch((err) => {
                console.error(err);
                reject(err);
              });
          });
        },

        enviarVerificacion: function () {
          return new Promise((resolve, reject) => {
            try {
              if (
                !vm.$root.acceso("EDITAR_ESTUDIOS") ||
                this.datos.estado == "Borrador"
              ) {
                delete this.datos.estado;
              }
              axios({
                method: "POST",
                url: `${process.env.VUE_APP_API_URL}/estudios`,
                data: {
                  ...this.datos,
                  idEstudio: vm.idEstudio || null,
                  estado:
                    this.datos.estado_actual.estado == "Borrador"
                      ? "Pendiente Revisión"
                      : this.datos.estado_actual.estado,
                  idTipo: vm.estudio.idTipo,
                },
              })
                .then(({ data }) => resolve(data))
                .catch((err) => {
                  console.error(err);
                  reject(err);
                });
            } catch (e) {
              console.error("ERROR ==>", e);
              console.error("ERROR ==>", e.response);
            }
          });
        },
      });
    },
    async enviarEstudio(borrador) {
      if (!this.$refs.formulario.validate() && !borrador) {
        this.erroresCampos = true;
        return;
      }
      this.errors = [];

      if (borrador)
        this.$set(this.checks, "guardandoBorrador", {
          progress: false,
          texto: "Guardando Borrador",
        });
      else
        this.$set(this.checks, "subiendoEstudio", {
          progress: false,
          texto: "Subiendo Estudio a verificación",
        });

      this.cargandoDatos = true;

      let estudioPotencial = this.estudioBuilder(this);

      if (borrador) {
        try {
          this.checks.guardandoBorrador.progress = "loading";
          const { idEstudio: idBorrador } =
            await estudioPotencial.guardarBorrador();
          this.checks.guardandoBorrador.progress = true;
          if (borrador && idBorrador) {
            this.$router.push({
              name: "EnviarEstudio",
              query: { idEstudio: idBorrador },
            });
            this.cargandoDatos = false;
            return;
          } else if (borrador && this.idEstudio) {
            this.$router.push({
              name: "Borradores",
            });
            this.cargandoDatos = false;
            return;
          }
          this.checks.guardandoBorrador.progress = true;
        } catch (e) {
          console.error(e);
          this.errors.push(e.response?.data);
          this.checks.guardandoBorrador.progress = "error";
          this.cargandoDatos = false;
          return;
        }
      } else {
        try {
          this.checks.subiendoEstudio.progress = "loading";
          const { idEstudio } = await estudioPotencial.enviarVerificacion();
          this.checks.subiendoEstudio.progress = true;

          this.$router.push({ name: "DetallesEstudio", query: { idEstudio } });
          return;
        } catch (e) {
          console.error(e);
          this.errors.push(e.response?.data);
          this.checks.subiendoEstudio.progress = "error";
          this.cargandoDatos = false;
          return;
        }
      }

      this.$router.push({ name: "Estudios" });
    },
    async eliminarEstudio() {
      if (
        !(await this.$refs.confirm.open(
          "Eliminar estudio",
          "¿Estás seguro de que quieres eliminar este borrador?",
          { confirmColor: "error", color: "error" }
        ))
      )
        return;

      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
        data: {
          motivo: "Eliminación borrador",
        },
      }).then((res) => {
        this.$router.push({ name: "Borradores" });
      });
    },
    removePolygon(pol) {
      pol.polygon.visible = false;
      this.estudio.cubiertas.splice(
        this.estudio.cubiertas.findIndex((item) => item.id == pol.id),
        1,
        pol
      );
    },
    async getTiposEstudio() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/tipos?disabled____list=false`,
        });
        this.tiposEstudios = data;
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar los tipos de estudio"
        );
      }
    },
    async getTarifas() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/tarifas`,
        });
        this.selects.tarifas = data.map((t) => ({
          ...t,
          text: t.nombreTarifa,
          value: t.idTarifa,
        }));
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar las tarifas"
        );
      }
    },
    async getNivelesInteres() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/nivel_interes`,
        });
        this.selects.nivelesInteres = data.map((t) => ({
          ...t,
          text: t.nombre,
        }));
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar los niveles de interés"
        );
      }
    },
    async getTiposMangueras() {
      try {
        const { data } = await axios({
          url: `${process.env.VUE_APP_API_URL}/cargadores/tipos_manguera`,
        });
        this.selects.tiposManguera = Object.entries(data).map(([k, v]) => ({ text: v, value: k }))
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar los tipos de manguera"
        );
      }
    },
    async getTiposSoporte() {
      try {
        const { data } = await axios({
          url: `${process.env.VUE_APP_API_URL}/cargadores/tipos_soporte`,
        });
        this.selects.tiposSoporte = Object.entries(data).map(([k, v]) => ({ text: v, value: k }))
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar los tipos de soporte"
        );
      }
    },
  },
  watch: {
    cargandoDatos(val) {
      if (val) this.checksPanel = true;
      else {
        setTimeout(() => {
          this.checksPanel = false;
          this.checks = {};
        }, 1000);
      }
    },
    "estudio.direccion"(val) {
      if (!val) return;
      clearTimeout(this.debouncer.direccion);
      this.debouncer.direccion = setTimeout(() => {
        //TODO: BUSCAR EN MAPS
        this.buscarDireccion();
      }, 1000);
    },
    cubiertasMarcadas(val) {
      if (val.length > 0 && !this.estudio.freezePosition) {
        const coords = val[0].polygon
          .getPath()
          .getArray()
          .map((p) => [p.lat(), p.lng()]);

        var x = coords.map((xy) => xy[0]);
        var y = coords.map((xy) => xy[1]);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        this.estudio.latitud = cx;
        this.estudio.longitud = cy;
      }
    },
  },
};
</script>

<style></style>
